import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ConstantsService } from './constants.service';
import { ApiService } from './api.service';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseURL: string = this.constantsService.API_URL_USER;

    constructor(public constantsService: ConstantsService, private apiService:ApiService,  private httpService: HttpService) { }

    /*
     *  Read user role
     */
    getRole(username: string): Observable<any> {
        let requestObject = {
            API_URL: this.baseURL + '/role/' + username,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_GET
        };
        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    getRegions(): Observable<any> {
        let username = JSON.parse(localStorage.getItem('username')||'')
        let requestObject = {
            API_URL:this.baseURL+'/get-regions/'+ username._value,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_GET
        };
        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Extract data from response
     */
    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
}
