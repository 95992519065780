<div class="deletePopup" style="font-family: Poppins;">
    <div class="d-flex justify-content-between align-items-center" style="background-color:#093868;">
        <div class="p-2">
            <span style="font-size: 15px;font-weight: 600; color: white;">{{'Alert!'}}</span>
        </div>
        <div class="p-2" style="margin: 0 0 -5px 0px !important;">
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
        </div>
    </div>
    <div class="my-2" style="text-align: center;font-weight: 500; font-size: 13px;">
        <p>{{this.paramsData}}</p>
    </div>
    <div class="d-flex justify-content-around align-items-center" style="padding: 15px;">
        <div style="margin: 5px;">
            <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close>{{ 'Ok'}}</button>
        </div>
    </div>
</div>