<div class="row page-title-header modal-header m-0">
    <div class="col-md-12 col-12 text-center">
      <span class="title">Select Region</span>
    </div>
  </div>
   
  <div style="padding: 20px 15px 0;">
    <mat-form-field style="width: 100%" appearance="outline" class="matFformFieldClient">
      <mat-label>Region</mat-label>
      <mat-select>
        <mat-option 
            style="font-family: 'Poppins';" 
            *ngFor="let env of envData" 
            [value]="env"
            (onSelectionChange)="onSelectionEnv(env)">
            {{env.region_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
   
  <div class="row" style="width: 100%">
    <div class="col-lg-12 col-md-12 col-sm-12 text-center btn-align">
      <mat-dialog-actions class="ml-4">
        <button [disabled]="!selectedEnv" class="du-btn du-custom-confirm-btn" (click)="envSelection(selectedEnv, $event)" matTooltip="Confirm" matTooltipClass="expand-tooltip">
          Confirm
        </button>
      </mat-dialog-actions>
    </div>
  </div>