import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-body',
  templateUrl: './app-body.component.html',
  styleUrls: ['./app-body.component.scss'],
})
export class AppBodyComponent implements OnInit {
  userName: string = '';
  client: any;

  constructor(private dashboardService: DashboardService, 
    private sessionService: SessionService,
    private route:Router) {
    }

  ngOnInit(): void {
    this.userName = this.sessionService.getUsername();
  }

  selectModule(module:any){
    // debugger
    // this.dashboardService.setModuleDetails(module);
    sessionStorage.setItem('module', JSON.stringify(module));
    this.route.navigate([`dashboard/${module.client_key}/${module.module_key}`])
    // return false
  }
}