import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import moment from "moment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root",
})
export class DynamicFilterService {
  moment = moment;
  previousSingleSelectedItems: any = [];
  previousMultiSelectedItems: any = [];
  backupLoadedData: any = {};
  baseQuery: any;
  payload: any = [];
  refreshGrid = new Subject<any>();
  filterQuery = new Subject<any>();
  resetForm = new Subject<any>();
  deepCopyOfRowSelection: any = [];

  fields: any;
  badges: any = [];

  selectedAllFilters: any = {};
  selectedFilters: any = {};
  resetBadge=false;

  private booleanSubject = new BehaviorSubject<boolean>(false); // Initial value
  boolean$ = this.booleanSubject.asObservable();
  
  constructor(private apiService: ApiService) {}

  setBoolean(value: boolean) {
    this.booleanSubject.next(value);
  }

  loadApiData(method: string, url: string, key: string, data: any) {
    if (method.toUpperCase() == "POST") {
      let request: any = {};

      let keys = key.split(",");

      for (let k of keys) {
        let keyName = k;

        if (data[keyName] instanceof Array) {
          request[keyName] = data[keyName]
            ? this.getFilter(data[keyName], keyName)
            : "";
        } else {
          request[keyName] = data[keyName] ? data[keyName][keyName] : "";
        }
      }

      return this.apiService.post(url, request);
    } else {
      return this.apiService.get(url);
    }
  }

  setFilterValue(values: any) {
    // debugger
    // auto query stored based on formly form data
    const query: any = {};
    for (let [key, value] of Object.entries(values)) {
      if (value instanceof String) {
        query[key] = value;
      } else if (value instanceof Date) {
        query[key] = moment(value).format("yyyy-MM-DD");
      } else if (value instanceof Object) {
        const v: any = value;
        if (v[key] == undefined) {
          let a: string = v?.map((a: any) => a[key]);
          query[key] = a.toString();
        } else {
          query[key] = v[key];
        }
      } else if (value instanceof Array) {
        query[key] = this.getFilter(value, key);
      }
    }

    // Main
    this.baseQuery = query;
    this.filterQuery.next(this.baseQuery);
    this.resetFilterChange();
  }

  getFilter(data: any[], paramName: any) {
    if (data.length == 0) return null;
    let filter = "";
    data.map((e) => {
      filter += e[paramName] + ",";
    });
    return filter.slice(0, filter.lastIndexOf(","));
  }

  getDvlogData() {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery);
    return this.apiService.post("api/dvlog/get-dvlog-data", query);
  }

  getDvlogCommentsData() {
    // if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    return this.apiService.get("api/filter/get-comments");
  }

  dVlogDeleteRecords(data: any) {
    console.log(data);
    return this.apiService.post("api/dvlog/delete-dvlog-records", data);
  }
  getDeleteLogs(data:any){
    return this.apiService.post("api/dvlog/get-dvlogstatus-data", data);
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  setBadges(fields: any) {
    this.badges = [];

    fields.forEach((f: any) => {
      const exist = this.badges.find((b: any) => b.key == f.key);

      if (!exist) {
        this.badges.push({
          key: f.key,
          allOpt: "",
          selectedOpt: "",
          label: f.templateOptions.label,
          type: f.templateOptions.type,
          showBy: f.templateOptions.showBy,
          labelProp: f.templateOptions.labelProp,
        });
      }
    });
  }

  resetFilter() {

    let data:any = sessionStorage.getItem("userState");
    data = JSON.parse(data);
    data.selectedFilters = {};
    sessionStorage.setItem("userState", JSON.stringify(data))
    this.resetForm.next(true);
  }

  resetFilterChange() {
    debugger
    let foundChanges = Object.keys(this.selectedAllFilters).find((key) => {
      if (JSON.stringify(this.selectedAllFilters[key]) !== JSON.stringify(this.selectedFilters[key])) {
          return true; // Found a difference
      }
      return false; // No difference found for this key
    });

    if(foundChanges) {
      this.resetBadge = true; 
    } else {
      this.resetBadge = false;
    } 
  }

  setBadgeValue(key: string, allOpt: any, selectedOpt: any) {
    this.badges.find((b: any) => {
      if (b.key == key) {
        b.allOpt = allOpt;
        b.selectedOpt = selectedOpt;
      }
    });
  }
}
