import { Auth } from 'aws-amplify';
import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';
import { ApiService } from 'src/app/services/api.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2000ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2000ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class LoginComponent implements OnInit, OnChanges {
  // @ViewChild('loginClose') loginClose: ElementRef<HTMLElement>;
  user: any;
  showQR: boolean = false;
  mfaMode: boolean = false;
  isValid: boolean = false;
  loginMode: boolean = true;
  resetMode: boolean = false;
  forgotMode: boolean = false;
  formSubmitted: boolean = false;
  firstTimeReset: boolean = false;
  showPassword: boolean = false;
  qrValue: any;
  backUrl: string = "";
  message: string = "";
  password: any = "";
  username: any = "";
  mfa_answer: string = "";
  mfaMessage: string = "";
  forgot_email: string = "";
  new_password: string = "";
  qrElementType: string = "url";
  confirm_password: string = "";
  verification_code: string = "";
  forgotErrorMessage: string = "";
  new_update_password: string = "";
  confirm_update_password: string = "";
  messageResetPassword: string = "";
  updatePasswordMessage: string = "";
  confirmAccountUrl: string = "/confirm-account";
  homePageURL: string = "home";
  rememberMe: boolean = false;
  currentImageIndex: number = 0;
  images: string[] = [
    'assets/login_frame1New.svg',
    'assets/login_frame2New.svg',
    'assets/login_frame3New.svg',
    'assets/login_frame4New.svg',
  ];
  isResetPasswordSuccess: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private apiService: ApiService
  ) {
    let that = this;

    this.loginMode = true;
    this.forgotMode = false;

    // this.route.queryParams.subscribe((params: any) => {
    // 	if (params['url']) {
    // 		that.isValid = true;
    // 		this.backUrl = params['url'];
    // 	} else {
    // 		that.isValid = false;
    // 	}
    // });
  }

  ngOnInit(): void {
    this.getEmailPassLocal()
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 3000); // Change image every 3 seconds
  }

  ngOnChanges(): void {

  }

  getEmailPassLocal() {
    if (localStorage.getItem('rememberMe') == 'true') {
      this.rememberMe = true
      this.username = this.sessionService.getUsernameLocal();
      this.password = localStorage.getItem('password')
    } else if (localStorage.getItem('rememberMe') == 'false') {
      this.rememberMe = false
    }
  }

  onRememberMeChange(event: any) {
    if (event.target.checked == true) {
      // sessionStorage.setItem('rememberMe', 'true')
      localStorage.setItem('rememberMe', 'true')
      sessionStorage.setItem('username', this.username)
      localStorage.setItem('password', this.password)
    } else {
      // sessionStorage.setItem('rememberMe', 'false')
      localStorage.setItem('rememberMe', 'false')
      sessionStorage.removeItem('username')
      localStorage.removeItem('password')
    }
  }

  login(Form: any) {
    let that = this;

    this.message = "";
    this.formSubmitted = true;

    if (Form.valid) {
      sessionStorage.clear();
      if (localStorage.getItem('rememberMe') == 'true') {
        sessionStorage.setItem('username', this.username)
        localStorage.setItem('password', this.password)
      } else {
        sessionStorage.removeItem('username')
        localStorage.removeItem('password')
      }

      try {
        Auth.signIn(this.username, this.password)
          .then(async (user: any) => {
            if (user) {
              this.user = user;

              if (
                user.challengeName &&
                user.challengeName == "NEW_PASSWORD_REQUIRED"
              ) {
                this.mfaMode = false;
                this.loginMode = false;
                this.resetMode = false;
                this.forgotMode = false;
                this.firstTimeReset = true;

                this.resetFirstTimePasswordForm();
              } else if (
                user.challengeName &&
                user.challengeName == "MFA_SETUP"
              ) {
                this.showQRCode();
              } else if (
                user.challengeName &&
                user.challengeName == "SOFTWARE_TOKEN_MFA"
              ) {
                this.showMFAVerification();
              } else {
                await this.setupUserSession();

                // let el: HTMLElement = this.loginClose.nativeElement;
                // el.click();
                // navigate to url
                window.location.href = this.backUrl;
              }
            }
          })
          .catch((error: any) => {
            switch (error.code) {
              case "UserNotConfirmedException":
                this.router.navigate([this.confirmAccountUrl]);
                break;
              case "NotAuthorizedException":
                this.message = "Invalid username or password";
                break;
              case "UsernameExistsException":
                this.message = "Invalid username or password";
                break;
            }
          });
      } catch (e: any) {
        this.message = e.message;
      }
    }
  }

  async showQRCode() {
    this.showQR = true;

    let value = await Auth.setupTOTP(this.user);
    this.qrValue = "otpauth://totp/DataUnveilAnalytics?secret=" + value;

    this.mfaMode = true;
    this.loginMode = false;
  }

  async showMFAVerification() {
    this.mfaMode = true;
    this.loginMode = false;
  }

  async verifyMFA() {
    let result: any;

    this.mfaMessage = "";

    try {
      if (this.showQR) {
        result = await Auth.verifyTotpToken(this.user, this.mfa_answer);
        if (result) {
          // Set preferred MFA for user
          Auth.setPreferredMFA(this.user, "TOTP");

          // navigate to dashboard
          setTimeout(() => {
            window.location.href = this.backUrl + this.homePageURL + "?l=1";
          }, 1000);
        } else {
          this.mfaMessage = "Cannot verify authentication code";
        }
      } else {
        result = await Auth.confirmSignIn(
          this.user,
          this.mfa_answer,
          "SOFTWARE_TOKEN_MFA"
        );

        if (result) {
          this.sessionService.setUsername(result.username);
          this.sessionService.setUsernameLocal(result.username);         
          this.setupUserSession();
          const userRole = await this.getUserRole(result.username);
          if (userRole) {
            this.sessionService.setUserRole(userRole);
          }

          setTimeout(() => {
            this.router.navigate([`${this.backUrl}/${this.homePageURL}/`],{ queryParams: { l: '1' } });
            // window.location.href = this.backUrl + this.homePageURL + "?l=1";
          }, 300);
        } else {
          this.mfaMessage = "Cannot verify authentication code";
        }
      }
    } catch (err: any) {
      switch (err.code) {
        case "NotAuthorizedException":
          this.mfaMessage = "Looks like last session was not logged out";

          setTimeout(() => {
            this.showLogin();
          }, 2000);

          break;

        case "EnableSoftwareTokenMFAException":
          this.mfaMessage = "Invalid code";
          break;

        case "CodeMismatchException":
          this.mfaMessage = "Invalid code";
          break;
      }
    }
  }

  async setupUserSession() {
    this.sessionService.setUser(this.user);
    this.sessionService.setUsername(this.username);
    this.sessionService.setUsernameLocal(this.username);
    this.sessionService.setUserDisplays(this.username);

  }

  getUserRole(username: string) {
    return new Promise((resolve, reject) => {
      this.userService.getRole(username).subscribe(
        (role) => {
          resolve(role);
        },
        () => {
          // ignore log errors
          resolve(null);
        }
      );
    });
  }

  showLogin() {
    this.forgotErrorMessage =''
    this.resetLogin();
    this.mfaMode = false;
    this.loginMode = true;
    this.isResetPasswordSuccess = false
    this.resetMode = false;
    this.forgotMode = false;
    this.firstTimeReset = false;
    this.getEmailPassLocal()
  }

  showFirstTimeResetPassword() {
    this.mfaMode = false;
    this.loginMode = false;
    this.resetMode = false;
    this.forgotMode = false;
    this.firstTimeReset = true;
  }

  showResetPassword() {
    this.resetForgotPassword();

    this.mfaMode = false;
    this.loginMode = false;
    this.resetMode = false;
    this.forgotMode = true;
    this.firstTimeReset = false;
  }

  showUpdatePassword() {
    this.resetUpdatePassword();

    this.mfaMode = false;
    this.resetMode = true;
    this.loginMode = false;
    this.forgotMode = false;
    this.firstTimeReset = false;
  }

  sendVerificationCode() {
    this.apiService.forgotPassowrd(this.forgot_email).subscribe(
      (resp: any) => {
        Auth.forgotPassword(this.forgot_email)
          .then((data: any) => {
            this.showUpdatePassword();
          })
          .catch((err: any) => { });
      },
      (error: any) => {
        if(error.error.text == 'Email sent with password reset link')
        {
          Auth.forgotPassword(this.forgot_email)
          .then((data: any) => {
            this.showUpdatePassword();
          })
          .catch((err: any) => { });
        } else {
        setTimeout(() => {
          this.forgotErrorMessage = error.error.text
      }, 6000)

        }
      }
    )
  }

  resetPassword() {
    Auth.completeNewPassword(this.user, this.new_password, {
      name: "test",
      given_name: "test",
    })
      .then((data: any) => {
        this.showLogin();
      })
      .catch((err: any) => {
        this.messageResetPassword = err.message;
      });
  }

  updatePassword() {
    this.updatePasswordMessage = "";

    Auth.forgotPasswordSubmit(this.username, this.verification_code, this.new_update_password).then((data: any) => {
      this.resetMode = false;
      this.isResetPasswordSuccess = true
      setTimeout(() => {
        this.resetAll();
        this.showLogin();
      }, 5000)
    }
    ).catch((err: any) => {
      if (err) {
        this.updatePasswordMessage = err.message;
        setTimeout(() => {
          this.updatePasswordMessage = "";
        }, 5000);
      }
    });

  }

  resetLogin() {
    this.password = "";
    this.username = "";
  }

  resetForgotPassword() {
    this.forgot_email = "";
  }

  resetUpdatePassword() {
    this.verification_code = "";
    this.new_update_password = "";
    this.confirm_update_password = "";
  }

  resetFirstTimePasswordForm() {
    this.new_password = "";
    this.confirm_password = "";
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  showConfirmPassword: boolean = false;
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  resetAll() {
    this.user = {};
    this.formSubmitted = false;
    this.message = "";
    this.password = "";
    this.username = "";
    this.forgot_email = "";
    this.new_password = "";
    this.confirm_password = "";
    this.verification_code = "";
    this.new_update_password = "";
    this.confirm_update_password = "";
  }
}