import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-popup-alert",
  templateUrl: "./popup-alert.component.html",
  styleUrls: ["./popup-alert.component.scss"],
})
export class PopupAlertComponent implements OnInit {
  paramsData: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private dialogRef: MatDialogRef<PopupAlertComponent>
  ) {
    this.paramsData = passedData["alert"];
  }

  ngOnInit(): void {
    console.log(this.paramsData);
  }

  close(event: any) {
    this.dialogRef.close(event);
  }
}
