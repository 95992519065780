import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";
import { SessionService } from "src/app/services/session.service";
import { ClientModelService } from "./client-model.service";
import { ConstantsService } from "src/app/services/constants.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ClientRolesComponent } from "../client-roles/client-roles.component";
import { HomeComponent } from "src/app/home/home.component";

@Component({
  selector: "app-client-model",
  templateUrl: "./client-model.component.html",
  styleUrls: ["./client-model.component.scss"],
})
export class ClientModelComponent implements OnInit, OnDestroy {
  
  reqSubcription: Subscription[] = [];
  clients:any = [];
  selectedClient:any
  userName:any;
  roles:any;
  isButtonClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private passedData: any,
    private dialogRef: MatDialogRef<ClientModelComponent>,
    private sessionService: SessionService,
    private dataService: DataService,
    private dialog: MatDialog,
    private constantsService: ConstantsService,
    public clientModelService: ClientModelService,
    public homeComponent: HomeComponent,
  ) {
    dialogRef.disableClose = true;
    this.clients = this.passedData.client;
    this.userName = this.sessionService.getUsername();
  }
  
  ngOnInit(): void {}

  clientSelection(client:any) {

    this.dialogRef.close(client);

    // if (!this.isButtonClicked) {
    //   this.isButtonClicked = true;
      
    //   this.selectedClient = client;

    //   // user roles checking
    //   let body = {
    //     username: this.userName,
    //     client_key: this.selectedClient.client_key,
    //   };
  
    //   this.reqSubcription.push(this.clientModelService.getClientRoles(body).subscribe((roles:any) => {
  
    //     if(!roles) return this.roles=[];
    //     this.roles =  roles ? roles : [];
        
    //     this.dialogRef.close();
        
    //     if (this.roles.length>1) {
    //       this.openUserRoles(this.roles);
    //     } else {
    //       if(this.roles.length==1) {
    //         this.clientModelService.newSetRoleDetails(this.roles[0]);
    //         this.selectConfigType()
    //       }
    //     }
    //   }, err=> {
    //     console.log('user Role', err);
    //     this.selectConfigType()
    //   })) 
    //   // Reset the button state after a certain delay (e.g., 2 seconds)
    //   setTimeout(() => {
    //     this.isButtonClicked = false;
    //   }, 2000);
    // } 
  }

  // getDBType() {
  //   this.dataService.getConfigType().subscribe((res) => {
  //     let configs = res ? res : [];
  //     // this.openConfig(configs)
  //   });
  // }


  // openUserRoles(role:any) {
  //   let dialogRef = this.dialog.open(ClientRolesComponent, {
  //     width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
  //     disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
  //     panelClass: "du-custom-modalbox",
  //     data: {
  //       clients: this.clients || [],
  //       roles: this.roles || []
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((value) => {
  //     if(value=='closeFromClientRole') {
  //       this.homeComponent.openClient(this.clients)
  //     }else if (value) {
  //       this.selectConfigType();
  //     }
  //   });
  // }

  // selectConfigType() {
  //   if (!environment.production) {
  //     let configs = [{"config_typ": "DB"},{"config_typ": "S3"}];
  //     // this.openConfig(configs) 
  //     // this.getDBType();
  //   } else {
  //     let obj = {
  //       selectedConfig: 'S3',
  //       selectedClient: this.selectedClient
  //     }
  //     this.dialogRef.close(obj);
  //   }
  // }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe());
  }  
}
