import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { MatComponentsModule } from "./../../mat-components.module";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClientModelComponent } from "./client-model/client-model.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ClientRolesComponent } from "./client-roles/client-roles.component";
import { AgGridCheckboxComponent } from './ag-grid-checkbox/ag-grid-checkbox.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { OverlayModule } from "@angular/cdk/overlay";
import { PopupDeleteComponent } from "./popup-delete/popup-delete.component";
import { FilterBadgesComponent } from './filter-badges/filter-badges.component';
import { PopupAlertComponent } from "./popup-alert/popup-alert.component";
import { LoaderComponent } from "../loader/loader.component";

@NgModule({
  declarations: [
    ClientModelComponent,
    ClientRolesComponent,
    AgGridCheckboxComponent,
    PopupDeleteComponent,
    FilterBadgesComponent,
    PopupAlertComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    AgGridModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    OverlayModule
  ],
  exports: [
    ClientModelComponent,
    ClientRolesComponent,
    AgGridCheckboxComponent,
    PopupDeleteComponent,
    FilterBadgesComponent,
    PopupAlertComponent,
    LoaderComponent
  ],
  bootstrap: [],
  providers: [{ provide: MatDialogRef, useValue: {} }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedComponentModule {}
