<div class="d-flex align-items-center">
    <span style="font-weight: 600;background-color: transparent !important;padding-left:0px;" class="chipsTag">{{ 'Selected Filter' }}</span>
    
    <span *ngFor="let badge of this.dynamicFilterService.badges" [ngClass]="{'chipsTag': badge.showBy !='no'}">
        
        <span 
            style="font-weight: 600;font-size: 10px;cursor: pointer !important;"  
            *ngIf="badge.showBy !='no'"
            [ngClass]="{'dropdown-toggle': badge.key != 'date'}"
            [cdkMenuTriggerFor]="badge.key == 'date' ? '' : menu"
            (click)="selectedValues(badge)"
        >

            <!-- Label for other than Date -->
            <span *ngIf="badge['type']!='date'" class="pr-1">{{ badge.label }}</span>

             <!-- Date  -->
            <span *ngIf="badge['type'] == 'date'">
                {{ moment(badge.selectedOpt['from_date']).format('MM/DD/YYYY')  + ' - ' +
                moment(badge.selectedOpt['to_date']).format('MM/DD/YYYY') }}
            </span>
            
            <!-- Single -->
            <span *ngIf="badge['type'] == 'single'" class="chipBgColor">
                {{ (badge.selectedOpt ? 1 : 0) + "/" + badge.allOpt.length }}
            </span>

            <!-- Multi -->
            <span *ngIf="badge['type'] == 'multi'" class="chipBgColor">
                {{badge.selectedOpt.length + "/" + badge.allOpt.length }}
            </span>

        </span>
    </span>

    <span style="background-color: rgba(209, 16, 68, 0.1);font-size: 10px;font-weight: 600;padding: 1px 10px;"  (click)="resetFilter()" class="chipsTag" *ngIf="this.dynamicFilterService.resetBadge">
        <span style="position: relative;color: #D11044;cursor: pointer;" class="d-flex align-items-center">
            <mat-icon class="matColorstyle" >close</mat-icon>
            <span>Reset</span>
        </span>
    </span>
</div>


<ng-template #menu>
    <div class="dropdownMenu" cdkMenu>
        <div style="background-color: #F5F8FA;padding: 10px 5px !important;margin: 2px;font-weight: 600;">Selected Values</div>
        <div class="p-1 m-1 overScrollMenu" style="min-width: 200px;width: auto;">
            <div class="disabled" *ngFor="let a of localData">
                {{ a.value ? a.value : '' }}
            </div>
        </div>
    </div>
</ng-template>