import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-popup-delete",
  templateUrl: "./popup-delete.component.html",
  styleUrls: ["./popup-delete.component.scss"],
})
export class PopupDeleteComponent implements OnInit {
  paramsData: any;
  paramData: any;
  config: any;
  message: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private dialogRef: MatDialogRef<PopupDeleteComponent>
  ) {
    this.paramsData = passedData["dvLog"];
    this.config = passedData["config"];
    this.message = passedData["message"];
  }

  ngOnInit(): void {
    console.log(this.paramsData);
  }

  close(event: any) {
    this.dialogRef.close(event);
  }
}
