import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientModelService {
  public apiBaseUrl: any
  public clientDetails: any;
  public roleDetails: any;
  constructor(
    private apiService: ApiService,
    private sessionService: SessionService
  ) {
    this.apiBaseUrl = environment.SERVER_ROOT_URL
  }

  getClientDetails(){
    return this.clientDetails;
  }

  setClientDetails(client: any){
    this.clientDetails = client;
  }

  newSetClientDetails(data:any) {
    let client = {
      user: this.sessionService.getUsername(),
      client: data,
    };

    let selectedClientDetails = [];
    selectedClientDetails.push(client);
    
    sessionStorage.setItem("client", JSON.stringify(selectedClientDetails));
    
    this.clientDetails = selectedClientDetails;

    // sessionStorage.setItem('switchClient', JSON.stringify(data.selectedClient))
    sessionStorage.setItem("selectConfig", data.selectedConfig);
  }

  getRoleDetails(){
    return this.roleDetails;
  }

  newSetRoleDetails(role: any){
    // storing session to roles
    let obj = {
      user: this.sessionService.getUsername(),
      roles: role,
    };
    let selectedRoleDetails = [];
    selectedRoleDetails.push(obj);
    sessionStorage.setItem("roles", JSON.stringify(selectedRoleDetails));
    this.roleDetails = selectedRoleDetails;
  }
  
  setRoleDetails(role: any){
    this.roleDetails = role;
  }


  getClientList(user: string) {
    let body = {
      'username': user
    }
    let url = `api/get-client-list`;
    return this.apiService.post(url, body)
  }

  getClientRoles(obj: any) {
    let body = {
      'username': obj.username,
      'client_key': obj.client_key
    }
    let url = `api/get-user-role`;
    return this.apiService.post(url, body)
  }

  getPageAccessList(obj: any) {
    let body = {
      'username': obj.username,
      'user_role_key': obj.user_role
    }
    let url = `api/get-page-access-details`;
    return this.apiService.post(url, body)
  }
}
