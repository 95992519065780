import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  @Output("sideToggle") sideToggle = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  openSidenav() {
    this.sideToggle.emit(true);
  }

}
