import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  public apiBaseUrl: any;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiBaseUrl = environment.SERVER_ROOT_URL
  }

  login(data: any) {
    return this.httpClient.post(this.apiBaseUrl + '/api/user-validation', data);
  }
  getuserName(data: any) {
    return this.httpClient.post(this.apiBaseUrl + '/api/validate-pwd-key', data);
  }

  forgotPassowrd(data: any) {
    const obj = {
      user_id: data
    }
    return this.httpClient.post(this.apiBaseUrl + '/api/send-pwd-confirmation', obj);
  }

  // Local JSON
  getDoc(url: string): Observable<any> {
    return this.httpClient.get(`assets/json/${url}.json`)
  }

  // API Get Method
  get(url: string): Observable<any> {
    console.log(this.apiBaseUrl+'/'+url)
    return this.httpClient.get(`${this.apiBaseUrl}/${url}`)
  }

  // API POST Method
  post(url: string, data: any): Observable<any> {
    
    let client:any=sessionStorage.getItem("client");
    let user_id:any=
    client= JSON.parse(client)
    data['client_key'] = client[0].client.client_key;
    data['user_id'] = client[0].user;

   // console.log(this.apiBaseUrl+'/'+url)

    return this.httpClient.post(`${this.apiBaseUrl}/${url}`, data)
  }
  
}
