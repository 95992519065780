import { Auth } from 'aws-amplify';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2000ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2000ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class LogoutComponent implements OnInit {
  images: string[] = [
    'assets/login_frame1New.svg',
    'assets/login_frame2New.svg',
    'assets/login_frame3New.svg',
    'assets/login_frame4New.svg',
  ];
  currentImageIndex: number = 0;

	constructor(
		private router: Router,
		private route: ActivatedRoute
	) {
		
  }

  ngOnInit(): void {
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 3000); // Change image every 3 seconds
  }

  logout() {
    window.location.href = "/login";
  }
}
