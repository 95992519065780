import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UtilityService } from './utility.service';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	constructor(
		private httpClient: HttpClient,
		public utilityService: UtilityService,
		public constantsService: ConstantsService
	) { }

	sendRequest(requestData:any): any {
		let url = this.constantsService.SERVER_ROOT_URL + requestData.API_URL;
		var header;
		if(requestData.REQUEST_HEADERS){
			header = new HttpHeaders(requestData.REQUEST_HEADERS)
		}else{
			header = new HttpHeaders({ 'Content-Type': 'application/json' })
		}
		let httpOptions = {
			withCredentials: false,
			headers: header
		};

		if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_GET) {
			return this.httpClient.get(url, httpOptions);
		} else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_POST) {
			return this.httpClient.post(url, requestData.BODY, httpOptions);
		} else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_PUT) {
			return this.httpClient.put(url, requestData.BODY, httpOptions);
		} else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_DELETE) {
			return this.httpClient.delete(url, httpOptions);
		}
	}
}