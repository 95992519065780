import { Component, Injectable, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { DataService } from "./../services/data.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { ClientModelService } from "../components/shared/client-model/client-model.service";
import { SessionService } from "../services/session.service";
import { ClientModelComponent } from "../components/shared/client-model/client-model.component";
import { UtilityService } from "../services/utility.service";
import { ConstantsService } from "../services/constants.service";
import { ClientRolesComponent } from "../components/shared/client-roles/client-roles.component";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../services/api.service";
import { AggService } from "../services/agg.service";
import { SessionStorage } from "angular-web-storage";
import { EnviornmentSelectionComponent } from "../components/enviornment-selection/enviornment-selection.component";

@Component({
  selector: "app-general",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

@Injectable({
  providedIn: "root",
})

export class HomeComponent implements OnInit, OnDestroy {
  clientKey: any;
  clientName: any;
  roleKey: any;
  roleName: any;
  clients: any;
  roles: any;
  userName: string = "";
  reqSubcription: Subscription[] = [];

  constructor(
    private route: Router,
    private dialog: MatDialog,
    private dataService: DataService,
    public utilityService: UtilityService,
    private sessionService: SessionService,
    private constantsService: ConstantsService,
    public clientModelService: ClientModelService,
    public apiService: ApiService,
    public aggService: AggService,
  ) {
    this.userName = this.sessionService.getUsername();
  }
  
  ngOnInit(): void {
    this.openEnvPopup();
    // if(sessionStorage.getItem('allClient')) {
    //   let session = sessionStorage.getItem('allClient')
    //   this.clients = session ? JSON.parse(session) : [];
    //   this.openClient(this.clients);
    // } else {
    //   this.getClient();
    // }
  }

  getClient() {
    this.aggService.getClientNames().subscribe((client:any)=>{
      if (!client) return this.clients=[]
      this.clients = client || [];
      sessionStorage.setItem('allClient' , JSON.stringify(this.clients));
      this.openClient(this.clients)
    }, err => {
      console.log(err);
    })
  }

  getClientRoles(client:any) {
    // user roles checking
    let body = {
      username: this.userName,
      client_key: client.client_key,
    };

    this.reqSubcription.push(this.clientModelService.getClientRoles(body).subscribe((roles:any) => {

      if(!roles) return this.roles=[];
      this.roles = roles ? roles : [];
      if (this.roles.length>1) {
        this.openClientRoles();
      } else {
        if(this.roles.length==1) {
          this.clientModelService.newSetRoleDetails(this.roles[0]);
        }
      }
    }, err=> {
      console.log('user Role', err);
    })) 
  }
 
  // Client Selection Popup
  openClient(clients:any) {
    let dialogRef = this.dialog.open(ClientModelComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
      panelClass: "du-custom-modalbox",
      data: {client: clients},
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.getClientClosed(value);
      }
    });
  }

  getClientClosed(value:any) {
    if (value) {
      this.clientModelService.newSetClientDetails(value);
      this.clientKey = value.client_key;
      this.clientName = value.client_nm;
      // this.getClientRoles(this.userName, this.clientKey);
      this.getModuleList();
    }
  }

  // Client Role Popup
  openClientRoles() {
    let dialogRef = this.dialog.open(ClientRolesComponent, {
      width: this.constantsService.MEDIUM_DIALOG_BOX_WIDTH,
      disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
      panelClass: "du-custom-modalbox",
      data: {
        clients: this.clients || [],
        roles: this.roles || []
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if(value=='closeFromClientRole') {
        if(this.clients.length>1) {
          this.openClient(this.clients)
        } else {
          this.openClientRoles()
        }
      }
    });
  }

  // navigate to dashboard and set to session userInformation and logos
  getModuleList() {
    this.route.navigate([`aggregation/`]);
    return
  }

  openEnvPopup() {
    // let data = [{'env': 'Dev'},{'env': 'Stage'}]

    let dialogRef = this.dialog.open(EnviornmentSelectionComponent, {
        width: this.constantsService.SMALL_DIALOG_BOX_WIDTH,
        disableClose: this.constantsService.DIALOG_DISABLE_CLOSE,
    });

    dialogRef.afterClosed().subscribe((value) => {
        console.log('selectedValue', value);

        if (value) {
          this.sessionService.setStoreData('envHeaderVariables', value);

          // URL using next screen
          this.getModuleList();
        }
    });
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe());
  }
}
