import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-enviornment-selection',
  templateUrl: './enviornment-selection.component.html',
  styleUrls: ['./enviornment-selection.component.scss']
})
export class EnviornmentSelectionComponent implements OnInit {

  envData:any;
  selectedEnv:any;
  data:any=[];
  loaderSet = true;

  constructor(private userService: UserService,
      @Inject(MAT_DIALOG_DATA) private passedData: any,
      private dialogRef: MatDialogRef<EnviornmentSelectionComponent>
  ) {}

  ngOnInit(): void {
    this.getRegions()
  }

  getRegions(){
    this.userService.getRegions().subscribe((res:any)=>{
      console.log(res);
      this.envData=res;
    })
  }

  close() {
    this.dialogRef.close('')
  }

  onSelectionEnv(data:any) {
    this.selectedEnv = data;
  }

  
  envSelection(env:any, $event:any) {
    if(this.loaderSet) {      
      this.dialogRef.close(this.selectedEnv)

      setTimeout(() => {
        this.loaderSet = false
      }, 2000);
    }
  }

}
