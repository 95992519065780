import { Injectable } from "@angular/core";
import { FilterService } from "./filter.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class UserActivityService {

  userStateData:any = {
    "username" : "",
    "selectedFilters" : {},
    "pageTab":0,
    "selectedType": ''
  }

  constructor(private sessionService: SessionService) {}

  private key = 'userState';

  getData(): any {
    const data:any = sessionStorage.getItem(this.key);
    return JSON.parse(data) || this.userStateData;
  }

  // Set Date for User filter
  setData(data: any): void {
    this.userStateData = this.getData()
    let userName = this.sessionService.getUser()
    this.userStateData['username'] = userName.username

    // Object into Array of Object
    let updatesObj:any = Object.entries(data).map(([key, value]) => ({ key, value }));

    for(let i = 0; i <  updatesObj.length; i++) {
      if(updatesObj[i].key == "selectedFilters") {
        let Obj:any = Object.entries(updatesObj[i].value).map(([key, value]) => ({ key, value }));
        Obj.forEach((res:any)=> {
          this.userStateData[updatesObj[i].key][res.key] = res.value
        })
      } else {
        this.userStateData[updatesObj[i].key] = updatesObj[i].value
      }
    }

    const serializedData:any = JSON.stringify(this.userStateData);
    sessionStorage.setItem(this.key, serializedData);
  }
  
}
