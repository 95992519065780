import { FilterService } from 'src/app/services/filter.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService } from './data.service';



@NgModule({})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        DataService,
        FilterService
      ]
    };
  }

 }
