import { MatComponentsModule } from '../../mat-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBodyComponent } from './app-body/app-body.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppBodyComponent,
    AppFooterComponent,
    AppHeaderComponent,
    DefaultLayoutComponent,
    AppSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  exports:[
    DefaultLayoutComponent,
    AppBodyComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent
  ]
})
export class AppLayoutModule { }
