<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <!-- Header -->
    <app-header (sideToggle)="snav.toggle()"></app-header>


    <mat-sidenav-container class="example-sidenav-container">    
        <mat-sidenav #snav [mode]="'over'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list>
                <a mat-list-item routerLink="aggregation/fixed-files">Fixed Files</a>
            </mat-nav-list>
        </mat-sidenav>

        
        <mat-sidenav-content class="body">
            <app-body class="content"></app-body>
            <app-footer class="footer text-center"></app-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

