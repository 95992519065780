import * as uuid from "uuid";
import { Injectable } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "angular-web-storage";

import { ConstantsService } from "./constants.service";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  tableDisplayMap: any = {};
  userAttributes = new Subject<any>();
  userName:any
  constructor(
    private constantsService: ConstantsService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {
  }

  setBreadCrumbs(breadCrumbs: any) {
    this.sessionStorageService.set("breadCrumbs", breadCrumbs);
  }

  getBreadCrumbs(): any[] {
    return this.sessionStorageService.get("breadCrumbs");
  }

  setLogMasterData(logMasterData: any[]) {
    this.sessionStorageService.set("logMasterData", logMasterData);
  }

  getLogMasterData(): any[] {
    return this.sessionStorageService.get("logMasterData");
  }

  setUser(user: any) {
    this.sessionStorageService.set("user", user);

    let sessionData = {
      session_id: new Date().getTime(),
    };
    this.setLogSession(sessionData);
  }

  getUser(): any {
    return this.sessionStorageService.get("user");
  }

  setLoginTime(date: any) {
    this.sessionStorageService.set("login_time", date);
  }
  setEmployeeId(id: any) {
    this.sessionStorageService.set("employee_key", id);
  }

  getLoginTime(): any {
    return this.sessionStorageService.get("login_time");
  }

  setUsername(username: any) {
    this.sessionStorageService.set("username", username);
  }
  
  setUsernameLocal(username: any) {
    this.localStorageService.set("username", username);
  }
  
  setUserDisplays(username: any) {
     
    this.sessionStorageService.set("userDisplay", username);
  }

  getUsername(): any {
    try {
      let user = JSON.parse(
        this.sessionStorageService.get("user").storage.user
      );
      return user.username;
    } catch (err) {
      return this.sessionStorageService.get("username");
    }
  }
  
  getUsernameLocal(): any {
    try {
      let user = JSON.parse(
        this.localStorageService.get("user").storage.user
      );
      return user.fullname;
    } catch (err) {
      return this.localStorageService.get("username");
    }
  }
  
  colorCodes:any
  logoImage:any
  getColorCodes() {
    let module = sessionStorage.getItem("module");
    if (module) {
      let color = JSON.parse(module);
      this.colorCodes = color.color_code;
    }
    if (this.colorCodes?.length == 1) {
      return this.colorCodes;
    } else {
      let a = "'linear-gradient(to right," + this.colorCodes?.toString() + ")'";
      return a
    }
  }
  getClintLogo() {
    let module = sessionStorage.getItem("module");
    if (module) {
      let image = JSON.parse(module);
      this.logoImage = image.logo_image;
    }
    if(this.logoImage) {
      return this.logoImage
    }
  }

  setUserSession(userSession: any) {
    this.sessionStorageService.set("userSession", userSession);
  }

  getUserSession(): any {
    return this.sessionStorageService.get("userSession");
  }

  isLoggedIn(): boolean {
    return this.sessionStorageService.get("user") != null;
  }

  setAvatar(avatarImage: string, collection: string) {
    sessionStorage.setItem("avatarImage", avatarImage);
    sessionStorage.setItem("avatarCollection", collection);
  }

  getEmpKey() {
    let emp:any = sessionStorage.getItem("employee_key");
    let employee_key = JSON.parse(emp)
    return employee_key._value
  }

  getClientKey() {
    let client_key: any = sessionStorage.getItem("client");
    let value = JSON.parse(client_key);
    return value[0].client.client_key
  }

  getAvatar() {
    let currentAvatar = null;

    if (sessionStorage.getItem("avatarImage") != null)
      currentAvatar =
        this.constantsService.AVATAR_URL +
        "/" +
        sessionStorage.getItem("avatarImage");

    return currentAvatar;
  }

  setUserRole(userRole: any) {
    this.sessionStorageService.set("userRole", userRole);
  }

  getUserRole(): any {
    return this.sessionStorageService.get("userRole");
  }

  // log session data methods

  setLogSession(logSessionData: any) {
    this.sessionStorageService.set("logSessionData", logSessionData);
  }

  getLogSession(): any {
    return this.sessionStorageService.get("logSessionData");
  }

  getClient() {
    let data: any = [];
    if (sessionStorage.getItem("client")) {
      let client: any = sessionStorage.getItem("client");
      data = client === "null" ? [] : JSON.parse(client);
      let user = this.getUsername();
      let found = data.find((obj: any) => obj["user"] === user);
      if (found) {
        return found.client;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }

    return data;
  }

  async getUserAttributes() {
    let auth:any = sessionStorage.getItem('authUser')
    auth = JSON.parse(auth)

    if(auth) return auth

    let authUser:any = await Auth.currentUserInfo();
    sessionStorage.setItem('authUser', JSON.stringify(authUser))
    // console.log(user)

    return authUser
  }

  //set headerVariables 
  setStoreData(keyName:string, data: any) {
    sessionStorage.setItem(keyName, JSON.stringify(data));
  }

  getStoreData(keyName:string) { //get headerVariables 
    if(sessionStorage.getItem(keyName)) {
        let data:any = sessionStorage.getItem(keyName);
        return JSON.parse(data);
    } else {
        return ''
    };
  }
}
