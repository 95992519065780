import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { MatComponentsModule } from "./../../mat-components.module";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { SharedComponentModule } from "../shared/shared.module";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    AgGridModule,
    SharedComponentModule
  ],
  exports: [
  ],
  bootstrap: [],
  providers: [{ provide: MatDialogRef, useValue: {} }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModulesComponentModule {}
