import Amplify from 'aws-amplify';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthModule } from './components/auth/auth.module';
import { MatComponentsModule } from './mat-components.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService} from './services/data.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FilterService } from './services/filter.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { ConstantsService } from './services/constants.service';
import { MyInterceptor } from './services/my.interceptor';
import { UserActivityService } from './services/useractivity.service';
import { AggService } from './services/agg.service';
import { AppLayoutModule } from './components/layout/app-layout.module';
import { SharedComponentModule } from './components/shared/shared.module';
import { SharedServicesModule } from './services/shared.services.module';
import { HttpService } from './services/http.service';
import { ModulesComponentModule } from './components/modules/modules.module';
import { DialogService } from './services/dialog.service';
import { EnviornmentSelectionComponent } from './components/enviornment-selection/enviornment-selection.component';

Amplify.configure({
	Auth: environment.AMPLIFY,
});

@NgModule({
  declarations: [
    AppComponent,
    EnviornmentSelectionComponent
  ],
  imports: [
    BrowserModule,
    QRCodeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule,
    AgGridModule,
    MatComponentsModule,
    SharedComponentModule,
    ModulesComponentModule
   ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    AggService,
    DataService,
    DatePipe,
    CurrencyPipe,
    DialogService,
    FilterService,
    UserActivityService,
    SharedServicesModule,
    { provide: MatDialogRef, useValue: {}},
    ConstantsService,
    HttpService
  ],
  exports:[
    MatComponentsModule
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
