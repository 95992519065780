<div class="deletePopup" style="font-family: Poppins;">
    <div class="d-flex justify-content-between align-items-center" style="background-color: #F6F8FB;">
        <div class="p-2">
            <span style="font-size: 15px;font-weight: 600;">{{config.header_name||'Delete'}}</span>
        </div>
        <div class="p-2" style="margin: 0 0 -5px 0px !important;">
            <mat-icon mat-dialog-close class="close-icon" style="color: rgba(138, 152, 171, 1);" >close</mat-icon>
        </div>
    </div>
    <div class="my-2" style="text-align: center;font-weight: 500; font-size: 13px;">
        
        <div *ngIf="message"> 
            <p>{{message}}</p>
        </div>
        
        <p>{{config.title || 'Are you sure you want to delete?'}}</p>

        <div *ngIf="paramsData"> 
            {{'['}} 
            <span *ngFor="let dvKey of this.paramsData;let last = last;">
                <span>{{  dvKey || ''}}</span>
                <span *ngIf="!last">{{', '}}</span>
            </span>
            {{']'}}
        </div>
       
        
    </div>
    <div class="d-flex justify-content-around align-items-center" style="padding: 15px;">
        <div style="margin: 5px;">
            <button type="button" class="btn btn-outline-primary btnDelete" mat-dialog-close>{{config.cancel_btn||'Cancel'}}</button>
        </div>
        <div style="margin: 5px;">
            <button type="button" class="btn btn-danger btnDelete" (click)="close('1')">{{config.confirm_btn||'Confirm'}}</button>
        </div>
    </div>
</div>