import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, from, throwError, BehaviorSubject } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { SessionService } from './session.service';
import { AuthService } from './auth.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private sessionService: SessionService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // Only JSON - API Like GET POST 
    if(request.responseType!="json") return next.handle(request)

    // Checking User
    if(!this.authService.isLoggedIn()) return next.handle(request);
    
    return from(Auth.currentSession()).pipe(
      switchMap((session) => {
        if (session.isValid()) {
          const token = session.getIdToken().getJwtToken();
          return this.handleRequestWithToken(request, next, token);
        } else {
          return throwError('Unauthorized');
        }
      }),
      catchError((error: HttpErrorResponse|any) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        }

        // when No current user
        if(error=='No current user') {
          this.authService.logoutAndRedirect();
        }
        if(error.message=='No current user') {
          this.authService.logoutAndRedirect();
        }
        
        return throwError(error);
      }),
      finalize(() => console.log())
    );
  }

  private handleRequestWithToken(request: HttpRequest<unknown>, next: HttpHandler, token: string): Observable<HttpEvent<unknown>> {
    
     
    if(this.authService.isLoggedIn()) {
      if(this.sessionService.getStoreData('envHeaderVariables')) {
        let duEnv:any = this.sessionService.getStoreData('envHeaderVariables')
        for (let key in duEnv) {
            let value = duEnv[key].toString()
            request = request.clone({
                headers: request.headers.set(key, value)
            });
        }
    }
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'user_id': this.sessionService.getUsername()
        }
      });
    }

    // console.log('Spinner Show')
    return next.handle(request);
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(Auth.currentSession()).pipe(
        switchMap((session) => {
          const token = session.getIdToken().getJwtToken();
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return this.handleRequestWithToken(request, next, token);
        }),
        catchError((error) => {
          // Handle refresh token failure (e.g., redirect to login page)
          return throwError('Unauthorized');
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap(() => {
          return this.handleRequestWithToken(request, next, this.sessionService.getUsername());
        })
      );
    }
  }
}
