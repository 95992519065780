import { Component, OnInit } from "@angular/core";
import { DynamicFilterService } from "../dynamic-filter/dynamic-filter.service";
import moment from "moment";

@Component({
  selector: "app-filter-badges",
  templateUrl: "./filter-badges.component.html",
  styleUrls: ["./filter-badges.component.scss"],
})
export class FilterBadgesComponent implements OnInit {
  moment = moment;
  localData: any = [];

  constructor(public dynamicFilterService: DynamicFilterService) {}

  ngOnInit(): void {}
  
  selectedValues(event: any) {
    this.localData = [];

    if (event.type != "date") {
      if (event.selectedOpt instanceof Array) {
        event.selectedOpt.forEach((res: any) => {
          if (res[event.labelProp]) {
            let a = {
              key: event.labelProp,
              value: res[event.labelProp],
            };
            this.localData.push(a);
          }
        });
      } else {
        let a = {
          key: event.labelProp,
          value: event.selectedOpt[event.labelProp],
        };
        this.localData.push(a);
      }
    } else {
      this.localData = [];
    }
  }

  resetFilter() {
    this.dynamicFilterService.resetFilter();
  }
}
