import { Auth } from 'aws-amplify';
import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2000ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2000ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})

export class ResetPasswordComponent implements OnInit, OnChanges {
  verification_code: string = "";
  new_update_password: string = "";
  confirm_update_password: string = "";
  isResetPasswordSuccess: boolean = false;
  showPassword: boolean = false;
  updatePasswordMessage: string = "";
  showReset: boolean = true
  username: any = "";
  currentImageIndex: number = 0;
  images: string[] = [
    'assets/login_frame1New.svg',
    'assets/login_frame2New.svg',
    'assets/login_frame3New.svg',
    'assets/login_frame4New.svg',
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length !== 0) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const value = params[key];
            if (key) {
              this.getUserName(key)
            }
            else {
              this.router.navigate(['/login'])
            }
          }
          else {
            this.router.navigate(['/login'])
          }
        }
      }
      else {
        this.router.navigate(['/login'])
      }

    });
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 3000); // Change image every 3 seconds
  }

  getUserName(key: any) {
    const obj = {
      validationkey: key
    }
    this.apiService.getuserName(obj).subscribe(
      (resp: any) => {
        if (resp) {
          this.username = resp[0]?.user_id
          if (!this.username) {
            this.router.navigate(['/login'])
          }
        } else {
          this.router.navigate(['/login'])
        }
      }),
      (error: any) => {
        this.router.navigate(['/login'])
      }
  }

  ngOnChanges(): void {
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  updatePassword() {
    this.updatePasswordMessage = "";
    if (this.confirm_update_password === this.new_update_password) {
      this.login()
    }
    else {
      this.updatePasswordMessage = "New password did not match with confirm password";
      setTimeout(() => {
        this.updatePasswordMessage = "";
      }, 5000)
    }
  }

  showConfirmPassword: boolean = false
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  showLogin() {
    this.router.navigate(['/login'])
  }

  resetAll() {
    localStorage.clear()
    this.verification_code = "";
    this.new_update_password = "";
    this.confirm_update_password = "";
  }

  login() {
    try {
      Auth.signIn(this.username, this.new_update_password)
        .then(async (user: any) => {
          if (user) {
            this.updatePasswordMessage = "This is old password, please enter new password";
        setTimeout(() => {
          this.updatePasswordMessage = "";
        }, 5000);
          }
        })
        .catch((error: any) => {
          this.resetAPI()
        })
    }
    catch (e: any) {
    }
  }

  resetAPI() {
    Auth.forgotPasswordSubmit(this.username, this.verification_code, this.new_update_password).then(
      (data: any) => {
        this.showReset = false
        this.isResetPasswordSuccess = true
        setTimeout(() => {
          this.showLogin();
        }, 7000)
      }
    ).catch((err: any) => {
      if (err) {
        this.updatePasswordMessage = err.message;
        setTimeout(() => {
          this.updatePasswordMessage = "";
        }, 5000);
      }
    });
  }
}

