import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    AVATAR_URL: string = '' //environment.AVATAR_URL;
    SERVER_ROOT_URL: string = environment.SERVER_ROOT_URL;

    REQUEST_METHOD_GET: string = 'GET';
    REQUEST_METHOD_PUT: string = 'PUT';
    REQUEST_METHOD_POST: string = 'POST';
    REQUEST_METHOD_DELETE: string = 'DELETE';

    API_URL_LOG: string = 'log';
    API_URL_META: string = 'meta';
    API_URL_USER: string = '/user';
    API_URL_REPORT: string = 'report';
    API_URL_BI_REPORT: string = 'powerbi';
    API_URL_CONFIG_UI: string = 'configui';
    API_URL_FILE_PROCESS_MONITORING: string = 'api/client-report';

    NUMBER_OF_COLUMNS_TO_SHOW_IN_TABLE: number = 3;

    DISPLAY_TYPE_FORM: string = 'FRM';
    DISPLAY_TYPE_TABLE: string = 'MR';
    TAB_DISPLAY_TYPE_TABLE: string = 'TAB-MR';
    DISPLAY_TYPE_MASTER_DETAIL: string = 'MD';
    TAB_DISPLAY_TYPE_MASTER_DETAIL: string = 'TAB-MD';

    DIALOG_BOX_WIDTH_WINDOW_SIZE: string = '90%';
    DIALOG_BOX_WIDTH: string = '1000px';
    DELETE_DIALOG_BOX_WIDTH: string = '500px';
    MEDIUM_DIALOG_BOX_WIDTH: string = '500px';
    XMEDIUM_DIALOG_BOX_WIDTH: string = '700px';
    SMALL_DIALOG_BOX_WIDTH: string = '400px';
    XSMALL_DIALOG_BOX_WIDTH: string = '200px';

    USER_INACTIVITY_TIMEOUT = 15;
    DIALOG_DISABLE_CLOSE: boolean = false;
    USER_INACTIVITY_WARNING_TIMEOUT_IN_SECOND = 100;//870;

    REPORT_TYPE_PL = 'PL';

    ACTION_TYPE_LOGIN = 'LOGIN';
    ACTION_TYPE_LOGOUT = 'LOGOUT';
    ACTION_TYPE_ACTION = 'ACTION';
    MESSAGE_TIMEOUT_IN_MILLI_SECONDS = 3000;
}
