import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class DataService {

  constructor(private apiService: ApiService) {}

  getJsonData(url:any) {
    return this.apiService.getDoc(url);
  }

  getApiData(url:any) {
    return this.apiService.get(url);
  }

  getApiPostData(url:any, data: any) {
    return this.apiService.post(url, data);
  }
}
