import { LoginComponent } from './components/auth/login/login.component';
import { DefaultLayoutComponent } from './components/layout/default-layout/default-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardGuard } from './services/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'aggregation',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuardGuard],
    children: [
        { path: 'operational-reports', loadChildren: () => import('./components/modules/operational-reports/operational-reports.module').then(m => m.OperationalReportsModule) },
        { path: 'fixed-files-reports', loadChildren: () => import('./components/modules/fixed-files/fixed-files.module').then(m => m.FixedFilesModule) },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [AuthGuardGuard]
})
export class AppRoutingModule { }
