<mat-toolbar style="background: #fff;" class="d-flex justify-content-between">
  <div class="container-fluid">
    <div class="d-flex flex-wrap align-items-center">
      
      <div class="mr-2">
        <button mat-icon-button (click)="openSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

      <div class="logo"></div>

      <div class="ms-auto">
        <button type="button" class="btn btn-outline-primary me-2">Login</button>
        <button type="button" class="btn btn-primary">Sign-up</button>
      </div>

    </div>
  </div>
</mat-toolbar>