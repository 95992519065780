import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AggService {
  
constructor(private apiService:ApiService){}

  getClientNames(){
    return this.apiService.get('api/file-processing/get-client-data')
  }  
}
