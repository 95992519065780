import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class DialogService {

  dialogRef: any;

  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar) {}

  public openDialog(content: any, val?: any, width?: any, height?:any): void {
    if (width == null) width = "20%";
    this.dialogRef = this.dialog.open(content, {
      width: width || "20%",
      data: {alert : val} || 'Helosnd',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
  
  public closeModal() {
    this.dialogRef.close();
  }
  public closeAll() {
    this.dialogRef.close();
  }

  public openSnackBar(message: string, action: string) {
    this._snackBar.open( " ⓘ " + message   , action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass:['warning-class']
    });
  }
}
